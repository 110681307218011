import React, { Component } from 'react';


class ProperAd extends Component {

    constructor(props){
        super(props)       
    }
	componentDidMount() {
        //console.log('did mount')
        //window.loadProperMain()
        return;
        var proper_adname = "dailyspikes_" + this.props.slot;
        //window.loadProperAd()
        //return true;
        switch(this.props.slot){
            case "2x300x250":
                window.propertag.cmd.push(function() { window.proper_display('dailyspikes_content_2'); });
                window.propertag.cmd.push(function() { window.proper_display('dailyspikes_content_3'); });
                break;

            case "test":

                break;
            default:
                window.propertag.cmd.push(function() { window.proper_display(proper_adname); });
        }
        
	}
    
	render() {
        var proper_id = "proper-ad-dailyspikes_" + this.props.slot;
        if(this.props.slot=='2x300x250'){
            return(
                <div className="rev-title clearfix" style={{border:"0px",marginTop:"20px"}}>              
                <div align="center" style={{paddingTop:"0px"}}>
                <div style={{display:"inline-block",paddingRight:"5px"}}>
                    <div id="" style={{overflow:"hidden"}}>
                        <div className="proper-ad-unit">
                        <div id="proper-ad-dailyspikes_content_2">  </div>
                        </div>
                    </div>
                </div>
                <div style={{display:"inline-block",paddingLeft:"5px"}}>
                    <div id="" style={{overflow:"hidden"}}>
                        <div className="proper-ad-unit">
                        <div id="proper-ad-dailyspikes_content_3">  </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            );
            
        }
        else if(this.props.slot == "content_1"){
            return(
                <div className="rev-title clearfix" style={{border:"0px",marginBottom:"20px"}}>

                <div style={{width:"100%",marginTop:"0px"}}>
                </div>
                
                <div className="proper-ad-unit">
                <div id={proper_id}>  </div>
                </div>
                </div>
            );
        }
        else if(this.props.slot=='bottom' || this.props.slot=='top'){
            return(
                <div className="rev-title clearfix" style={{border:"0px",marginBottom:"10px"}}>

                <div style={{width:"100%",marginTop:"10px"}}>
                </div>
                
                <div className="proper-ad-unit">
                <div id={proper_id}>  </div>
                </div>
                </div>
            );
        }
        else if(this.props.slot=='left'){
            return(
                <div className="rev-title clearfix" style={{border:"0px"}}>

                <div className="proper-ad-unit">
                <div id={proper_id}>  </div>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className="rev-title clearfix"> 
                <div className="proper-ad-unit">
                <div id={proper_id}>  </div>
                </div>
                </div>
            );

            



        }
	}
}

export default ProperAd;