import React from 'react'
//import Helmet from 'react-helmet'
import Layout from '../components/layout'
//import Banner from '../components/Banner'
//import PrevNext from '../components/prevnext'
//import { graphql } from 'gatsby'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FaCalendar } from "react-icons/fa/"
import { FaUser } from "react-icons/fa/"
import SEO from '../components/SEO'
import config from '../config/config'
import Share from '../components/share'
import ProperAd from '../components/ProperAd'
//import { kebabCase } from 'lodash'

function nextPageURL (pageIndex,total_pages,next_article_url,folderPath,slug){
    let nextPageNumber = ""
    let nextPageUrl = "" 
    nextPageNumber = pageIndex + 2
    if(pageIndex == (total_pages-1)){
        nextPageUrl = next_article_url
    }
    else{
        nextPageUrl = `${folderPath}${slug}/${nextPageNumber}`
    }
    return nextPageUrl
}

/** If downloadRemote files failed, use direct S3 URLs in image */
function failSafeImage(block){
    if(block.imageLocal!=null){
        return <Img fluid={block.imageLocal.childImageSharp.fluid} style={{textAlign:"center",width:"100%"}} />
    }else{
        return <img src={block.image.url} style={{textAlign:"center",width:"100%"}} />
    }
}


const SlideshowNav = props => {

    let nextClass = "col-12"
    let displayPrev = {display:'none'}
    let prevPageNumber = ""
    //let nextPageNumber = ""
    //let nextPageUrl = ""
    if(props.pageIndex > 0){
        nextClass = "col-8"
        displayPrev = {display:'block'}

        prevPageNumber = props.pageIndex
        
    }
    
    let nextPageUrl = nextPageURL (props.pageIndex,props.total_pages,props.nextArticle,props.folderPath,props.slug)
    

    prevPageNumber = prevPageNumber == 1 ? "" : prevPageNumber

    return (
        <div>

            
            <div className="post-pagination clearfix grid-wrapper-nav">
                <div className="col-4" style={displayPrev}>
                    <Link to={`${props.folderPath}${props.slug}/${prevPageNumber}`} className="btn n-btn PreviousBottomButton">
                    <span className="fa fa-arrow-left PreviousBottomButton"></span>
                    </Link>
                </div>
                <div className={nextClass}>
                    <Link to={nextPageUrl}  className="btn n-btn NextBottomButton">
                    NEXT <span className="fa fa-arrow-right NextBottomButton"></span>
                    </Link>
                </div>
            </div>

            
        </div>
        
    )
}

const PageHeader = props =>{
    const sub_heading  = props.curPageData.sub_heading
    let headerItem = <h2 dangerouslySetInnerHTML={{__html:sub_heading}}></h2>
    return headerItem
}

const PageContent = props => {

    const curPageData  = props.curPageData 
    const blocks = curPageData.blocks 

    var showAds = 0
    let twoAds = <ProperAd slot='2x300x250' />
    

    let returnList = blocks.map( function(block,index)  {
        
        showAds = showAds === 1 ? showAds+1 : showAds

        let buffer = ""
        if(block.text){
            let str = block.text;
            console.log(str.replace('&nbsp;', ""))
            if(str.trim()!='<p>&nbsp;</p>')
                buffer = <p dangerouslySetInnerHTML={{__html:block.text}}></p>
            else 
                buffer = ''
        }
        let img = ""
        if(block.image.url){
            img = 
            <Link to={props.nextPageUrl}>
            {failSafeImage(block)}
            </Link>

            showAds = showAds + 1 
        }
        
        return (
            <p>
            {buffer}
            {img}
            {showAds === 1 && twoAds}
            </p>
        )
        
    })

    
    return returnList
}

const GalleryTemplate = (props) => {

    const { dailySpikesData: post } = props.data
    const { pageIndex } = props.pageContext 

    let nextPageNumber = pageIndex + 2

    // prepare current page's content
    let curPageData = post.content.pages[pageIndex]

    let nextPageUrl = nextPageURL (pageIndex,post.total_pages,post.next_article.next_article_url,"/article/",post.content.slug)
    
    //console.log(curPageData)


    //const { prev, next } = props.pageContext
    
    //let tags = post.frontmatter.tags 

    // Image path needs to fix after downloaded data from live site
    // think about adding new-cms-path instead of cms in path too
    // domain.com/cms2/article/slug ???
        return (
            <Layout>
                <SEO
                title={post.title}
                description={post.description || ''}
                image={post.content.featured_image.url}
                pathname={'/article/'+post.content.slug}
                article
                />
                

                <div id="main" className="alt">
                    <section id="one">
                        <div className="inner">
                            

                        


                            <header className="major">
                                <h1>{post.title}</h1>
                            </header>
                            
                            <div className="content">
                            <ul style={{listStyle:'none',marginLeft:'-1.5em',fontSize:'0.8em'}}>
                                <li style={{display:'inline-block'}}><FaUser size={12} />{`${post.content.author.firstname} ${post.content.author.lastname}`}</li>
                                <li style={{display:'inline-block'}}><FaCalendar size={12} /> {post.content.published}</li>
                            </ul>
                            </div>
                            
                            <span style={{margin:'0 0 20px -10px',display:'flex'}}>
                            <Share 
                            url={config.siteUrl +'/article/'+ post.content.slug}
                            title={`${post.title} - ${config.siteTitle}`}
                            image={config.siteUrl + post.content.featured_image.url}
                            twitterUsername={config.twitterUsername}
                            hashTag={`${config.hashTag}`}
                            /> 
                            </span>
                            

                            <div className="wrapperContent">
                                
                                <div className="mainContent">
                                    <div><PageHeader curPageData={curPageData}/></div>
                                    <ProperAd slot="content_1"/>
                                    
                                    
                                    
                                    <div><PageContent curPageData={curPageData} nextPageUrl={nextPageUrl} /></div>

                                    <SlideshowNav 
                                    nextPageNumber={nextPageNumber} 
                                    slug={post.content.slug}
                                    folderPath="/article/"
                                    pageIndex={pageIndex}
                                    nextArticle={post.next_article.next_article_url}
                                    total_pages ={post.total_pages}
                                    />

                                    <ProperAd slot="top"/>

                                </div>
                                <div className="sidebarContent">   
                                <ProperAd slot="side_1"/>
                                <ProperAd slot="side_2"/>
                                </div>
                            </div>
                            
                            
                           

                            
                            
                            
                        </div>
                    </section>
                </div>

            </Layout>
        )
    
}

export default GalleryTemplate


export const pageQuery = graphql`
    query GalleryByPath($id : String!) {
        dailySpikesData(id: { eq: $id }) {
            id
            title
            description
            og_description
            og_image
            og_title
            quiz_style
            robots
            total_pages
            type
            next_article {
              next_article
              next_article_url
            }
            content {
              id
              created
              published(formatString: "MMMM DD, YYYY")
              quiz_description
              quiz_results
              slug
              tags
              related {
                featured_image
                slug
                title
              }
              author {
                firstname
                lastname
              }
              categories {
                id
                name
              }
              featured_image {
                caption
                source_url
                url
              }
              layout
              pages {
                index
                sub_heading
                blocks {
                  index
                  text
                  imageLocal{
                    childImageSharp{
                        fluid (maxWidth:1800, quality:80){
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                  }
                  image {
                    caption
                    source_url
                    url
                  }
                }
              }
            }
            
        }


       
    }
`


